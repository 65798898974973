import React from "react";

import { HasuraConScheduleContent } from "../../../../components/hasuracon2021/Schedule";
import { HasuraConSEO } from "../../../../components/hasuracon2021/HasuraConSEO";
import HeroOverviewPattern from "../../../../components/hasuracon2021/images/hero-overview-pattern.png";
import Layout from "../../../../components/hasuracon2021/Layout";
import interestedSpeakerGraphic from "../../../../components/hasuracon2021/images/interested-speaker-graphic.png";

const canonicalUrl = "https://hasura.io/events/hasura-con-2021/schedule/";

const HasuraCon = ({ location, pageContext }) => {
  const { presentations } = pageContext;

  return (
    <Layout
      location={location}
      heroGraphic={() => (
        <img
          className="heroBanRightPattern"
          loading="lazy"
          src={HeroOverviewPattern}
          alt="Speakers Graphic"
        />
      )}
      sideBarGraphic={() => (
        <img className="leftInterested" src={interestedSpeakerGraphic} alt="Graphic" />
      )}
    >
      <HasuraConSEO canonicalLink={canonicalUrl} />
      <HasuraConScheduleContent presentations={presentations} />
    </Layout>
  );
};

export default HasuraCon;
