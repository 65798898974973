import React, { useState, useEffect } from "react";

import { scrollToTop } from "../LeftSidebar";
import { NavigationStrip } from "./NavigationStrip";
import { ScheduleHeader } from "./Header";
import { RegisterCTA } from "./RegisterCTA";
import PresentationListItem from "../homepage/PresentationListItem";

export const HasuraConScheduleContent = ({ presentations }) => {
  const [filter, setFilter] = useState("conference");
  const [activeDay, setActiveDay] = useState(23);

  useEffect(() => {
    if (activeDay === 24) {
      document
        .getElementById("day2-thursday")
        .scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
    }

    if (activeDay === 23) {
      scrollToTop();
    }
  }, [filter, activeDay]);

  const wednesdayTalks = presentations
    .sort((p1, p2) => {
      return p1.node.childMdx.frontmatter.position - p2.node.childMdx.frontmatter.position;
    })
    .filter(p => !p.node.name.includes("placeholder"))
    .filter(({ node }) => {
      const { day } = node.childMdx.frontmatter;

      return day === 1;
    })
    .filter(({ node }) => {
      const { type } = node.childMdx.frontmatter;

      if (filter === "workshop") {
        return type === "workshop";
      }

      if (filter === "topic") {
        return type === "topic";
      }

      return type !== "workshop" && type !== "topic";
    })
    .map((presentation, index) => {
      return (
        <PresentationListItem
          key={index}
          presentation={presentation}
          base="/events/hasura-con-2021/talks"
          isScheduleSection
        />
      );
    });

  const thursdayTalks = presentations
    .sort((p1, p2) => {
      return p1.node.childMdx.frontmatter.position - p2.node.childMdx.frontmatter.position;
    })
    .filter(p => !p.node.name.includes("placeholder"))
    .filter(({ node }) => {
      const { day } = node.childMdx.frontmatter;

      return day === 2;
    })
    .filter(({ node }) => {
      const { type } = node.childMdx.frontmatter;

      if (filter === "workshop") {
        return type === "workshop";
      }

      if (filter === "topic") {
        return type === "topic";
      }

      return type !== "workshop" && type !== "topic";
    })
    .map((presentation, index) => {
      return (
        <PresentationListItem
          key={index}
          presentation={presentation}
          base="/events/hasura-con-2021/talks"
          isScheduleSection
        />
      );
    });

  return (
    <section className="scheduleSectionWrapper sectionWrapper removePaddTop">
      <ScheduleHeader />
      <NavigationStrip
        filter={filter}
        activeDay={activeDay}
        setFilter={setFilter}
        setActiveDay={setActiveDay}
      />
      <div className="wednesday-wrapper" id="day1-wednesday">
        <h2 className="articleTitle">Wednesday, June 23</h2>
        <div className="conf-start-banner">
          <p>8:50 am PST</p>
          <div className="conf-heading">Conference begins</div>
        </div>
        <div className="presentationListWrapper">{wednesdayTalks}</div>
      </div>
      <div className="thursday-wrapper" id="day2-thursday">
        <h2 className="articleTitle">Thursday, June 24</h2>
        <div className="conf-start-banner thursday-banner">
          <p>8:50 am PST</p>
          <div className="conf-heading">Conference begins</div>
        </div>
        <div className="presentationListWrapper">{thursdayTalks}</div>
      </div>
      <RegisterCTA />
    </section>
  );
};
