import React from "react";

export const ScheduleHeader = () => (
  <div className="cocWrapper">
    <h1 className="title">Schedule</h1>
    <div className="articleDesc">
      We’re excited to bring over 20 speakers from across the world together for HasuraCon’21. They
      join us from enterprises, large companies, small agencies, as independent creators — and
      everything in between. Check out the Hasura Con’21 lineup!
    </div>
  </div>
);
